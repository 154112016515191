<template>
    <BackofficeBase :loader_prop="loader_prop">
      <div class="item_list">
        <div>
          <router-link
            :to="{
              path: '/backoffice/courier_company_edit',
              query: { id: 0 },
            }"
            >הקמת חברת הפצה</router-link
          >
        </div>
        <div v-for="couriercompany in view_data" :key="couriercompany.id">
          <h1>{{ couriercompany.name }}</h1>
          <!--
          <p>{{ couriercompany.contact_name }}</p>
          <p>{{ couriercompany.email }}</p>
          <p>{{ couriercompany.contact_phone }}</p>
          <p>{{ couriercompany.address }}</p>
          <p v-if="couriercompany.status == 1">פעילה</p>
          <p v-else>לא פעילה</p>
          -->
          <router-link
            :to="{
              path: '/backoffice/courier_company',
              query: { id: couriercompany.id },
            }"
            >לניהול חברת ההפצה</router-link
          >
          <div>
            <table class="distribution_point_table">
              <tr>
                <th>סטטוס</th>
                <th>כתובת חברה</th>
                <th>טלפון איש קשר</th>
                <th>אימייל איש קשר</th>
                <th>שם איש קשר</th>
              </tr>
              <tr>
                <td><p v-if="couriercompany.status == 1">פעילה</p>
                    <p v-else>לא פעילה</p>
                </td>
                <td>{{ couriercompany.address }}</td>
                <td>{{ couriercompany.contact_phone }}</td>
                <td>{{ couriercompany.email }}</td>
                <td>{{ couriercompany.contact_name }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  
  export default {
    name: "BackofficeCourierCompanies",
    components: {
      BackofficeBase,
    },
    data() {
      return {
        loader_prop: true,
        view_data: [],
      };
    },
    async mounted() {
      let backendModel = new BackendModel();
      let view_data = await backendModel.backendRequest(
        "/Api/service/backoffice/get_courier_companies",
        {}
      );
      this.view_data = view_data.data.view_data;
      this.loader_prop = false;
      console.log(this.view_data);
    },
  };
  </script>
  
  <style scoped>
  @import "../../assets/style.css";
  </style>
  
  